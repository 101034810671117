import React from 'react'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[`makewerks`, `3dprint`, `maker`, `design`, `functional`, `filament`]}
        />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3
                css={css`
                  margin-top: ${rhythm(4/3)};
                  margin-bottom: 0;
                `}
              >
                <Link css={css`box-shadow: none; color: #917c6fff;`} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              {/* <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} /> */}
              <Img
                sizes={node.frontmatter.featuredImage.childImageSharp.sizes}
                css={css`
                  margin-top: ${rhythm(1/2)};
                  margin-bottom: ${rhythm(1/2)};
                `}
              />
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        social {
          twitter
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630, maxHeight: 354) {
                  ...GatsbyImageSharpSizes_tracedSVG  
                }
              }
            }
          }
        }
      }
    }
  }
`